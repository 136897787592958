import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import PopOutAvatar from "./PopOutAvatar";
import avatarImage from './assets/me.png';
import ss1 from './assets/1.png';
import ss2 from './assets/2.png';
import ss3 from './assets/3.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import './App.css'; // Import the CSS file

function App() {
  // State for dark mode
  const [darkMode] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const projects = [
    {
      src: ss1,
      title: "Try Out Online",
      description: "Try Out berbasis online dengan sistem token."
    },
    {
      src: ss2,
      title: "ABPK ber-KB",
      description: "Aplikasi ABPK ber-KB untuk mempermudah pengguna dalam mengakses informasi."
    },
    {
      src: ss3,
      title: "Pronalin",
      description: "Perencanaan Persalinan dan Pencegahan Komplikasi."
    }
  ];

  // Define light and dark styles
  const containerStyle = {
    fontFamily: "'Raleway', sans-serif",
    color: darkMode ? "#f4f4f9" : "#333",
    backgroundColor: darkMode ? "#1e1e1e" : "#f4f4f9",
    margin: "0",
    padding: "0",
    boxSizing: "border-box",
    minHeight: "100vh",
  };

  const headerStyle = {
    textAlign: "center",
    padding: "40px",
    background: darkMode
      ? "linear-gradient(135deg, #667eea, #764ba2)"
      : "linear-gradient(135deg, #667eea, #764ba2)",
    color: "#fff",
  };

  const buttonRowStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  };

  const sectionStyle = {
    padding: "10px 40px",
    margin: "20px auto",
    maxWidth: "800px",
  };

  const footerStyle = {
    textAlign: "center",
    padding: "20px",
    marginTop: "40px",
    background: darkMode ? "#333" : "#333",
    color: "#fff",
    fontSize: "14px",
  };

  const buttonStyle = {
    padding: "10px 15px",
    border: "none",
    borderRadius: "12px",
    cursor: "pointer",
    background: "rgba(255, 255, 255, 0.2)",
    backdropFilter: "blur(10px)",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    margin: "0 10px",
    fontSize: "24px",
    color: "#fff",
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <div style={containerStyle}>
      <header style={headerStyle}>
        <PopOutAvatar
          avatarSrc={avatarImage}
        />
        <h1 style={{ marginBottom: "10px" }}>Hello, I'm Fajar Malik!</h1>
        <p style={{ marginTop: "0" }}>Tech Enthusiast</p>
        <div style={buttonRowStyle}>
          <a
            href="https://linkedin.com/in/elmaleek03"
            target="_blank"
            rel="noopener noreferrer"
            style={buttonStyle}
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href="mailto:fajarmalik.45@gmail.com"
            style={buttonStyle}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <a
            href="https://github.com/elmaleek03"
            target="_blank"
            rel="noopener noreferrer"
            style={buttonStyle}
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </div>
      </header>

      <section style={sectionStyle}>
        <h2>About Me</h2>
        <p>
          Currently a Mobile Developer. Always curious and excited to create something, love to solving problem and building a user-friendly apps. I love exploring tech too, and sometimes diving into UI/UX design.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2>Recent Works</h2>
        <div>
          <Carousel
            responsive={responsive}
            draggable={false}
            showDots={true}
            infinite={true}
            containerClass="carousel-container"
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            transitionDuration={500}
          >
            {projects.map((project, index) => (
              <div className="carousel-item" key={index}>
                <img
                  src={project.src}
                  alt={project.title}
                  className="carousel-image"
                  onClick={() => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                  }}
                />
                <div className="overlay">
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </section>

      <footer style={footerStyle}>
        End of line.
      </footer>

      {isOpen && (
        <Lightbox
          slides={projects.map((project) => ({ src: project.src }))}
          open={isOpen}
          index={photoIndex}
          close={() => setIsOpen(false)}
          onIndexChange={setPhotoIndex}
        />
      )}
    </div>
  );
}

export default App;