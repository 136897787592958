import React from "react";
import "./PopOutAvatar.css";

const PopOutAvatar = ({ avatarSrc }) => {
    return (
        <div className="popout-wrapper">
            <div className="popout-content">
                <div className="popout-circle"></div> {/* White circle background */}
                <img src={avatarSrc} alt="Profile" className="popout-person" />
            </div>
        </div>
    );
};

export default PopOutAvatar;
